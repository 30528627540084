'use client'

import React, {
	createContext,
	NamedExoticComponent,
	useEffect,
	useState,
} from 'react'
import { CustomWebApp } from '@/lib/types/TelegramTypes'
import { setCookie } from 'cookies-next'

export interface ITelegramContext extends CustomWebApp {}

export const TelegramContext = createContext<ITelegramContext>({
	isReady: false,
} as ITelegramContext)

const { Provider } = TelegramContext

interface ITelegramContextProvider {
	children: React.ReactNode
}

export const _TelegramProvider: NamedExoticComponent<ITelegramContextProvider> =
	React.memo(({ children }) => {
		const [webApp, setWebApp] = useState<CustomWebApp>({} as CustomWebApp)
		const [isReady, setIsReady] = React.useState<boolean>(false)

		useEffect(() => {
			const app = window.Telegram.WebApp

			if (app) {
				app.ready()
				setIsReady(true)
				setWebApp(app)

				setCookie('apiKey', app.initData)
				setCookie('user_id', app.initDataUnsafe?.user?.id)
				setCookie('tg-version', app.version)
				setCookie('platform', app.platform)

				// Init Settings
				if (!app.isExpanded) {
					app.expand()
				}
				app.disableVerticalSwipes()
				app.setHeaderColor('#000000')
			}
		}, [])

		const value = React.useMemo(() => {
			return { ...webApp, isReady }
		}, [webApp, isReady])

		return (
			<Provider value={value}>
				{/* {!isReady && <MainLoader />} */}
				{children}
			</Provider>
		)
	})

export const useTelegram = () => React.useContext(TelegramContext)
