'use client';
import React from 'react';

import '@/scss/globals.scss';
import MainLoader from '@/components/MainLoader/MainLoader';
import { setCookie } from 'cookies-next';
import { useTelegram } from '@/context/useTelegramContext';
import { useRouter } from 'next/navigation';
import { RoutesEndpoint } from '@/lib/utils/Routes.endpoint';

export default function Page() {
    const { initData } = useTelegram();
    const router = useRouter();

    React.useEffect(() => {
        if (initData) {
            setCookie('apiKey', initData);
            router.push(RoutesEndpoint.ACTIVE_CONTEST);
        }
    }, [initData]);
    return <MainLoader />;
}
