export const RoutesEndpoint = {
    ACTIVE_CONTEST: '/contest/active-contest',
    CONTEST: (contest_id: number) => `/contest/${contest_id}`,
    RULES: '/rules',
    CONTESTS_HISTORY: '/contests-history',
    SPONSORS: '/sponsors',
    SPONSOR_RATING: '/sponsor-rating',
    USER_PANEL: (tab: USER_PANEL_TABS | null = 'registration') => `/user-panel`,
    REGISTRATION_PARTICIPANTS: '/registration-participants',
    ADMIN_PANEL: '/admin-panel/admin',
    ADMIN_CREATE_CONTEST: '/admin-panel/create-contest',
    ADMIN_EDIT_RULES: '/admin-panel/edit-rules',
};

export type USER_PANEL_TABS = 'registration' | 'sponsor' | 'contestList';
