import React from 'react';

import'./mainLoader.scss';

const MainLoader = () => {
  return (
    <div className="base-loader">
      <span className="loader"></span>
      <p>Загрузка данных...</p>
    </div>
  );
};

export default MainLoader;